// TODO: Delete if not used
.drive-text-with-link {
    @apply p-8;

    .sep {
        @apply bg-black;
        @apply w-508;
        @apply mt-53px;
        @apply mx-auto;
        @apply mb-0;
        @apply h-half-px;
    }
}
