/*
 * Classes generated by Gutenberg editor
 */
.has-blue-dark-background-color {
    @apply bg-blue-dark;
}

.has-teal-dark-background-color {
    @apply bg-teal-dark;
}

.has-teal-dark-color {
    @apply text-teal-dark;
}

.wp-block-button__link {

    &.has-teal-dark-color {
        @apply text-teal-dark;

        &:hover,
        &:focus,
        &:active,
        &:visited {
            @apply text-teal-dark;
        }
    }
}

@screen lg {
    .wp-block-cover {

        &.drive-cover {
            min-height: rem(584);
        }
    }
}

@media (max-width: theme('screens.lg')) {
    .drive-md-one-column {

        .wp-block-column:not(:only-child) {
            flex-grow: 1 !important;
            flex-basis: 100% !important;
            margin-left: 0 !important;
        }
    }
}
