@media (max-width: theme('container.screens.xl')) {
	.wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right {
		grid-template-columns: 100% !important;
		.wp-block-media-text__media {
			grid-column: 1 !important;
			grid-row: 2 !important;
		}
		.wp-block-media-text__content {
			grid-column: 1 !important;
			grid-row: 1 !important;
		}
	}
}
